import React, { Component } from 'react'
import { connect } from 'react-redux'
import { userAdd, userDel, usersFetch } from './store/actionCreators'

function SingleItem (props) {
  function handleClick () {
    console.log(props.itemId)
    props.onDelete({ id: props.itemId })
  }
  return (
    <div>
      <pre>{JSON.stringify(props.item, null, 2)}</pre>
      <button onClick={handleClick}>Delete</button>
    </div>
  )
}

class Items extends Component {
  constructor (props) {
    super(props)
    this.state = { name: '' }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount () {
    this.props.usersFetch()
  }

  handleChange (event) {
    this.setState({ name: event.target.value })
  }

  handleSubmit (event) {
    this.props.userAdd({ name: this.state.name })
    this.setState({ name: '' })
    event.preventDefault()
  }

  render () {
    const itemsList = this.props.items.map((item, index) => {
      const key = item && item.id ? item.id : index
      return (
        <SingleItem
          onDelete={this.props.userDel}
          key={key}
          itemId={key}
          item={item}
        />
      )
    })
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <label>
            Name:
            <input
              type='text'
              value={this.state.name}
              onChange={this.handleChange}
            />
          </label>
          <input type='submit' value='Submit' />
        </form>
        <h1>Items List</h1>
        <ul>{itemsList}</ul>
      </div>
    )
  }
}

const mapStateToProps = state => ({ items: state.users })
const mapDidpatchToProps = { userAdd, userDel, usersFetch }
export default connect(
  mapStateToProps,
  mapDidpatchToProps
)(Items)
