import React from "react";
import ReactDOM from "react-dom";
import { compose, createStore, applyMiddleware } from "redux";
import {
  delayMiddleware,
  usersMiddleware,
  apiMiddleware
} from "./store/middleware";
import logger from "redux-logger";
import { Provider } from "react-redux";
import persistState from "redux-localstorage";
import { rootReducer } from "./store/reducers";

import App from "./App";

const enhancer = compose(
  applyMiddleware(logger, delayMiddleware, usersMiddleware, apiMiddleware),
  persistState()
);
const store = createStore(rootReducer, enhancer);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
