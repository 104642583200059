import { combineReducers } from "redux";
import { USER_ADD, USER_DEL } from "./actions";

const usersReducer = (state = [], action) => {
  switch (action.type) {
    case USER_ADD:
      return state.concat([action.payload]);
    case USER_DEL:
      return state.filter(item => item.id !== action.payload.id);
    // return state.concat([action.payload]);
    default:
      return state;
  }
};
const initialState = {
  counter: 0
};
const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case "INC":
      return { counter: state.counter + 1 };
    case "DEC":
      return { counter: state.counter - 1 };
    default:
      return state;
  }
};

export const rootReducer = combineReducers({
  users: usersReducer,
  ui: uiReducer
});
