import { API, USER_ADD, USERS_FETCH } from './actions'
import axios from 'axios'

export const usersMiddleware = store => next => action => {
  next(action)
  if (action.type === USER_ADD) {
    next({
      type: API,
      payload: {
        url: '/api/users',
        method: 'POST',
        data: action.payload
      }
    })
  }
  if (action.type === USERS_FETCH) {
    next({
      type: API,
      payload: {
        url: '/api/users',
        method: 'GET'
      }
    })
  }
}

export const delayMiddleware = store => next => action => {
  // let result = next(action);
  setTimeout(() => next(action), 1 * 1000)
}

export const apiMiddleware = store => next => action => {
  next(action)
  if (action.type !== API) {
    return
  }
  const { method, url, data } = action.payload
  const config = { method, url }
  if (data && ['GET', 'DELETE'].includes(method)) {
    config.params = data
  }
  if (data && ['POST', 'PUT'].includes(method)) {
    config.data = data
  }
  axios(config)
    .then(res => console.log('Success!'))
    .catch(error => console.error(error.message))
}
