import React from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Items from "./Items";

export const Login = props => (
  <div>
    <h1>Login</h1>
    E-mail: <input type="email" />
  </div>
);

const App = props => {
  return (
    <div>
      <p>Redux Example</p>
      <p>{props.counter}</p>
      <p>
        <button onClick={props.decrement}>-</button>
        <button onClick={props.increment}>+</button>
      </p>
      <Router>
        <Route path="/" exact component={Items} />
      </Router>
      <img
        alt="logo"
        src="https://cdn-images-1.medium.com/max/1200/1*VeM-5lsAtrrJ4jXH96h5kg.png"
      />
    </div>
  );
};

const mapStateToProps = state => ({ counter: state.ui.counter });
const mapDispatchToProps = dispatch => ({
  increment: () => dispatch({ type: "INC" }),
  decrement: () => dispatch({ type: "DEC" })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
