import { USER_ADD, USER_DEL, API, USERS_FETCH } from './actions'
import { uuid } from 'uuidv4'

export const userLogin = email => ({
  type: API,
  payload: {
    email
  }
})

export const userAdd = content => ({
  type: USER_ADD,
  payload: {
    id: uuid(),
    content
  }
})

export const userDel = ({ id }) => ({
  type: USER_DEL,
  payload: {
    id
  }
})

export const usersFetch = () => ({
  type: USERS_FETCH
})
